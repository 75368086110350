import React from 'react';
import { box } from './CTABox.module.scss';
import ContactForm from '../ContactForm';

const CTABox = () => {

    return (<div className={box}>
        <h2>READY TO TAKE ACTION?</h2>
        <p>Get in touch with us today to find out how we can help you achieve your sustainability, regulatory and business goals.</p>
    
        <ContactForm/>
    </div>)

}

export default CTABox;