import React, { useEffect, useRef, useState } from "react";
import circs from "../../images/svgs/circle-grid.svg";
import * as styles from "./IntroBlock.module.scss";
import { Container, Row, Col } from "react-bootstrap";

interface IntroBlockProps {
  children?: any;
}

// const IntroBlock = ({ children }: IntroBlockProps) => {

//   return (
//     <Container style={{overflow: 'hidden'}}>
//       <Row>
//         <Col xs={12}>
//           <div className={styles.container}>
//             <img src={circs} />
//             <div className={styles.text}>
//               { children }
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default IntroBlock;

const IntroBlock = ({ children }: IntroBlockProps) => {
  return (
    <Container style={{ overflow: "hidden" }}>
      <Row>
        <Col xs={12}>
          <div className={styles.container}>
            <div className={styles.child1}>
              <img src={circs} />
            </div>

            <div className={styles.child2}>{children}</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default IntroBlock;
