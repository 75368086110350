import React from 'react';
import * as styles from './AccentBar.module.scss';


const AccentBar = ({ children }) => {
    return <div className={styles.container}>
        <span className={styles.bar} id="bar"></span>
        <div className={styles.textContainer}>
            { children }
        </div>
    </div>
}

export default AccentBar;