import { Box, CircularProgress, Alert, styled, InputBase, TextField } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


const TBLInput = styled(TextField)({
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label': {
        color: 'white',
    },
    '& input': {
        color: 'white'
    },
    '& textarea': {
        color: 'white'
    }
  });
  

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.message) {
      errors.message = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      number: "",
      message: "",
    },
    validate,
    onSubmit: (values, resetForm) => {
      submit(values, resetForm);
    },
  });

  const clearForm = () => {
    formik.setValues({
      name: "",
      number: "",
      email: "",
      message: "",
    });
  };

  const submit = async (values, resetForm) => {
    if (!executeRecaptcha) {
      return;
    }

    setIsSubmitting(true);

    const token = await executeRecaptcha("test");

    // validate form
    if (!token) {
      return;
    }

    axios
      .post(
        "https://40m9tgm4th.execute-api.eu-west-2.amazonaws.com/prod/verify-captcha",
        {
          token,
        },
        {
          headers: {
            "X-Api-Key": "lixdKITSataLlEj47lddh70zLO3HUNS54NNyFD7L",
          },
        }
      )
      .then((res) => {
        if (res.data.score < 0.6) {
          return;
        }

        // const testData = {
        //   name: "Jack Minchin",
        //   email: "jackminchin@gmail.com",
        //   number: "+44 7805 310 420",
        //   message: "This is a test message.",
        // };
        const body = {
          ...values,
          score: res.data.score,
        };

        axios
          .post(
            "https://40m9tgm4th.execute-api.eu-west-2.amazonaws.com/prod/form-submission",
            values,
            {
              headers: {
                "X-Api-Key": "lixdKITSataLlEj47lddh70zLO3HUNS54NNyFD7L",
              },
            }
          )
          .then((res) => {
            setIsSubmitting(false);
            setIsSuccess(true);
            clearForm();
            setError(null);
            setTimeout(() => {
              setIsSuccess(false);
            }, 10000);
          })
          .catch((err) => {
            console.log(err);
            setIsSubmitting(false);
            setError(true);
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        setError(true);
      });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid>
          <Row style={{ marginBottom: 25 }}>
            <Col>
              {isSuccess && (
                <Alert severity="success">
                  Message sent! We'll be in touch soon.
                </Alert>
              )}
              {error && (
                <Alert severity="error">
                  Something went wrong! We're working on fixing it, but please
                  send you enquiry to info@tblgroup.co.uk.
                </Alert>
              )}
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col xs={6}>
              <TBLInput
                id="name"
                name="name"
                label="Name"
                variant="filled"
                onChange={formik.handleChange}
                value={formik.values.name}
                style={{ width: "100%" }}
                helperText={formik.errors.name ? formik.errors.name : null}
                color={formik.errors.name ? "warning" : "primary"}
                required
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col xs={6}>
              <TBLInput
                id="email"
                name="email"
                label="Email"
                variant="filled"
                onChange={formik.handleChange}
                value={formik.values.email}
                required
                helperText={formik.errors.email ? formik.errors.email : null}
                color={formik.errors.email ? "warning" : "primary"}
                style={{ width: "100%" }}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col>
              <TBLInput
                id="number"
                name="number"
                label="Phone Number"
                variant="filled"
                onChange={formik.handleChange}
                value={formik.values.number}
                style={{ width: "100%" }}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TBLInput
                id="message"
                name="message"
                label="Message"
                variant="filled"
                onChange={formik.handleChange}
                value={formik.values.message}
                required
                helperText={
                  formik.errors.message ? formik.errors.message : null
                }
                color={formik.errors.message ? "warning" : "primary"}
                rows={6}
                multiline
                style={{ width: "100%", color: 'white'}}
                onBlur={formik.handleBlur}  
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 20,
              }}
            >
              <Box sx={{ display: "flex" }}>
                {isSubmitting && (
                  <CircularProgress size={30} style={{ margin: 5 }} />
                )}
                <Button
                  type="submit"
                  color="white"
                  variant="contained"
                  disabled={isSubmitting || !formik.isValid}
                  style={{ backgroundColor: "white" }}
                >
                  Submit
                </Button>
              </Box>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default ContactForm;
