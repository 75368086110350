import React from "react";
import SEO from "../components/SEO";
import Layout from "../Layout";
import Hero from "../components/Hero";
import { Container, Row, Col } from "react-bootstrap";
import AccentBar from "../components/AccentBar";
import IntroBlock from "../components/IntroBlock";
import { StaticImage } from "gatsby-plugin-image";

import CivicaLogo from "../images/pages/partners/logos/civica.svg";
import aa from "../images/pages/partners/logos/aa.svg";
import am from "../images/pages/partners/logos/am.svg";
import bentley from "../images/pages/partners/logos/bentley.svg";
import bmw from "../images/pages/partners/logos/bmw.svg";
import bunkerworld from "../images/pages/partners/logos/bunkerworld.png";
import calogo from "../images/pages/partners/logos/calogo.png";
import DECC from "../images/pages/partners/logos/DECC.jpg";
import defra from "../images/pages/partners/logos/defra.jpg";
import ec from "../images/pages/partners/logos/ec.svg";
import envagency from "../images/pages/partners/logos/envagency.jpg";
import fcdo from "../images/pages/partners/logos/fcdo.png";
import icf from "../images/pages/partners/logos/icf.png";
import jaguar from "../images/pages/partners/logos/jaguar.svg";
import landrover from "../images/pages/partners/logos/landrover.svg";
import lear from "../images/pages/partners/logos/lear.svg";
import un from "../images/pages/partners/logos/un.svg";
import wmg from "../images/pages/partners/logos/wmg.svg";
import worldbank from "../images/pages/partners/logos/worldbank.svg";
import CTABox from "../components/CTABox";

const ClientsandPartners = () => {
  return (
    <Layout>
      <SEO title="Clients and Partners"></SEO>

      <Hero imageName="partnersBackground">
        <AccentBar>
          <div>
            <h1 className="whiteText partnersHeading">
              Expanding the reach <br />
              of sustainable practices <br />
              and climate change action
            </h1>
          </div>
        </AccentBar>
      </Hero>

      <IntroBlock>
        <h1>Clients and Partners</h1>
        <p>
          Our extensive client list shows how closely we work with some of the
          most significant household brands and global government institutions
          to tackle Climate change and sustainability action.{" "}
        </p>
      </IntroBlock>

      <section>
        <div style={{ marginTop: "-8%" }}>
          <h3>Partnered Companies</h3>
          <Col style={{ paddingTop: 50 }} xs={6} sm={12}>
            <img src={CivicaLogo} />
          </Col>
        </div>
      </section>

      <section>
        <Row>
          <Col>
            <h3>Companies we have collaborated with</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={aa} alt="" style={{ width: "20%" }} />
            <img src={am} alt="" style={{ width: "16%" }} />
            <img
              src={bentley}
              alt=""
              style={{ width: "16%", paddingLeft: 20 }}
            />
            <img src={bmw} alt="" style={{ width: "8%", paddingLeft: 20 }} />
            <img
              src={bunkerworld}
              alt=""
              style={{ width: "12%", paddingLeft: 20 }}
            />
            <img
              src={calogo}
              alt=""
              style={{ width: "15%", paddingLeft: 15 }}
            />
            <img src={DECC} alt="" style={{ width: "10%", paddingLeft: 25 }} />
            <img src={defra} alt="" style={{ width: "10%", paddingLeft: 20 }} />
            <img src={ec} alt="" style={{ width: "13%", paddingLeft: 22 }} />
            <img
              src={envagency}
              alt=""
              style={{ width: "16%", paddingLeft: 20 }}
            />
            <img src={fcdo} alt="" style={{ width: "11%", paddingLeft: 20 }} />
            <img src={icf} alt="" style={{ width: "10%", paddingLeft: 20 }} />
            <img
              src={jaguar}
              alt=""
              style={{ width: "13%", paddingLeft: 20 }}
            />
            <img
              src={landrover}
              alt=""
              style={{ width: "14%", paddingLeft: 20 }}
            />
            <img src={lear} alt="" style={{ width: "18%" }} />
            <img src={un} alt="" style={{ width: "10%", paddingLeft: 20 }} />
            <img src={wmg} alt="" style={{ width: "18%", paddingLeft: 25 }} />
            <img
              src={worldbank}
              alt=""
              style={{ width: "20%", paddingLeft: 25 }}
            />
          </Col>
        </Row>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ClientsandPartners;
